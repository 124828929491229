import React from 'react';
import { Link, graphql } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { ICSGuide } from '../../../modules/common/model/graphql-types';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { Employee } from '../../../modules/cs/common/components/employee';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Row, Col } from 'react-bootstrap';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else if (node.data.uri.includes('youtube.com')) {
        return (
          <Row className="video">
            <Col xs={12} lg={6}>
              <div className="video-responsive">
                <iframe
                  src={node.data.uri}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <div className="employee-container">
            <Employee slug={node.data.uri} mode="icon" enablePopover />
          </div>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IGuideNodes {
  nodes: ICSGuide[];
}

interface IGuideEntry {
  currentGuide: IGuideNodes;
}

interface IProps {
  data: IGuideEntry;
}

const CSRaidBritra: React.FC<IProps> = ({ data }) => {
  const guide = data.currentGuide.nodes[0];
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Raid - Britra</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_raidbritra.jpg"
            alt="Advanced gearing"
          />
        </div>
        <div className="page-details">
          <h1>Raid - Britra</h1>
          <h2>A guide for the Britra Raid in Counter Side.</h2>
          <p>
            Last updated: <strong>18/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Advanced gearing" />
        <div>{renderRichText(guide.content, options)}</div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSRaidBritra;

export const Head: React.FC = () => (
  <Seo
    title="Raid - Britra | Counter Side | Prydwen Institute"
    description="A guide for the Britra Raid in Counter Side."
  />
);

export const pageQuery = graphql`
  query {
    currentGuide: allContentfulCsGuides(filter: { guideId: { eq: "5" } }) {
      nodes {
        ...CsGuidesFieldsFragment
      }
    }
  }
`;
